import {getPagecluster} from "@otto-ec/global-resources/misc";
import {submitMerge, submitMiniAction, submitMove, trackOnNextPageImpression, submitEvent} from "@otto-ec/tracking-bct";

export default class TrackingService {

    sendLoadedTrackingEvent(featureOrder, featureIndex, promoType, promoFeatureLabels, masTrackingLabels) {
        const trackingData = [
            this._createContentLabel(featureOrder, featureIndex, promoType, masTrackingLabels, "loaded"),
            this._createContentTileLabel(featureIndex, promoType, promoFeatureLabels, "loaded")
        ];
        submitMerge({}, trackingData);
    }

    sendClickTrackingEvent(featureOrder, featureIndex, promoType, promoFeatureLabels, masTrackingLabels) {
        let trackingData = {
            name: "click",
            features: [
                this._createContentLabel(featureOrder, featureIndex, promoType, masTrackingLabels, "clicked"),
                this._createContentTileLabel(featureIndex, promoType, promoFeatureLabels, "clicked")]
        }
        let pageCluster = getPagecluster();
        const labels = {
            "wk.promo_AttributionFeature": [promoType + "_" + pageCluster],
            "wk.promo_AttributionSource": ["manual"]
        }
        submitMove(labels, trackingData);
        trackOnNextPageImpression({"promo_Click": promoType});
    }

    sendScrollTrackingEvent(promoType, featureIndex) {
        let parentFeature = {'featureId': this._getParentFeatureId(promoType, featureIndex)}
        let tileFeature = {'featureId': this._getTileFeatureId(promoType, featureIndex)}
        submitMiniAction([parentFeature, tileFeature]);
    }

    sendVideoTrackingEvent(trackingData) {
        submitEvent(trackingData);
    }

    _createContentLabel(featureOrder, featureIndex, promoType, masTrackingLabels, status) {
        return {
            id: this._getParentFeatureId(promoType, featureIndex),
            name: promoType,
            position: featureOrder,
            status: status,
            labels: {
                ...masTrackingLabels,
                promo_Feature: ["true"],
                promo_FilledSlots: ["1"],
                promo_FeatureNumber: [(featureIndex + 1).toString()]
            }
        }
    }

    _createContentTileLabel(featureIndex, promoType, promoContent, status) {
        return {
            id: this._getTileFeatureId(promoType, featureIndex),
            parentId: this._getParentFeatureId(promoType, featureIndex),
            name: promoType + "-" + this._getPromoTypeChildName(promoType),
            position: 1,
            status: status,
            labels: this._createContentTileLabels(promoContent)
        };
    }

    _createContentTileLabels(featureLabels) {
        return {
            ...featureLabels,
            promo_Source: ["manual"],
        };
    }

    _getPromoTypeChildName(promoType) {
        switch (promoType) {
            case 'Textlink': {
                return "Link";
            }
            case 'Video': {
                return "Video";
            }
            case 'PictureInfoCard' : {
                return "Tile";
            }
            default: {
                return "unknown"
            }
        }
    }

    _getParentFeatureId(promoType, featureIndex) {
        return 'ft3_' + this._getIdByPromoType(promoType) + featureIndex;
    }

    _getTileFeatureId(promoType, featureIndex) {
        return this._getParentFeatureId(promoType, featureIndex) + '_tile';
    }

    _getIdByPromoType(promoType) {
        switch (promoType) {
            case 'Textlink': {
                return "tl";
            }
            case 'Video': {
                return "vi";
            }
            case 'PictureInfoCard' : {
                return "pic";
            }
            default: {
                return "unknown"
            }
        }
    }
}
