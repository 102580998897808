import {eventLoader} from '@otto-ec/global-resources/event-loader';
import TrackingService from "./tracking_service";

export class CliplisterVideo {
    private trackingService = new TrackingService();

    constructor() {
        this.initVideos();
    }

    private getPlayerConfig(requestKey: string, autoplay: boolean, cliplisterElementId: string) {
        return {
            parentId: cliplisterElementId,
            customer: 86605,
            assetKeys: [requestKey],
            keyType: 10000,
            backgroundColor: '#000000',
            autoplay,
            defaultQuality: 'auto',
            assetLimit: 1,
            stage: {
                width: '100%',
                aspectRatio: 'asset'
            },
            plugins: {
                ClickableVideo: {
                    layer: 1
                },
                InnerControls: {
                    breakpoints: {
                        s: 447 /*                            */
                    },
                    layer: 2,
                    id: 'controls',
                    blacklist: ['playback-speed', 'share'],
                    template: {
                        type: 'external',
                        source: 'https:/*                                                                                           */
                    }
                },
                BufferingSpinner: {
                    layer: 3
                },
                PreviewImage: {
                    layer: 4
                },
                PlayButton: {
                    id: 'playButton',
                    layer: 5,
                    image: 'https:/*                                                                                        */
                    width: 72,
                    height: 72
                }
            }
        };
    }

    private enrichElementWithRandomId(element: Element): string {
        const randomString = Math.random().toString(36)
            .substring(7);
        element.setAttribute('id', randomString);
        return randomString;
    }

    private prepareExternalCliplisterStuff(callWhenExternalCliplisterIsAdded: (event: any) => void): void {
        const script: HTMLScriptElement = document.createElement('script');
        script.type = 'text/javascript';
        /*                                                                                                   */
        script.onreadystatechange = callWhenExternalCliplisterIsAdded;
        script.onload = callWhenExternalCliplisterIsAdded;
        script.src = 'https:/*                                                                                                                                                                                                                                                    */

        const elements = document.getElementsByTagName('head');
        if (elements !== null && elements.length > 0) {
            elements[0].appendChild(script);
        }
    }

    private createPlayer(cliplisterVideo: Element, context: CliplisterVideo): CliplisterViewer | null {
        if (cliplisterVideo.getAttribute('id')) {
            return null;
        }
        const cliplisterVideoId: string = context.enrichElementWithRandomId(cliplisterVideo);
        const requestKey: string | null = cliplisterVideo.getAttribute('data-requestkey');
        if (requestKey === null) {
            return null;
        }
        const autoplay: boolean = cliplisterVideo.getAttribute('data-autoplay') === "true";
        context.trackVideoView(requestKey);
        /*                   */
        const player: CliplisterViewer = new window.Cliplister.Viewer(context.getPlayerConfig(requestKey, autoplay, cliplisterVideoId));
        let videoLength: any = null;

        player.onReady((): void => {
            const assets = player.getAssets();
            const currentAssetIndex = player.getCurrentAssetIndex();
            const currentAsset = assets[currentAssetIndex];

            videoLength = currentAsset.duration;
        });

        player.onPlay((): void => {
            context.trackVideoStart(requestKey, videoLength);
        });

        player.onProgress10((): void => {
            context.trackVideoProgress(requestKey, videoLength, 0.1);
        });

        player.onProgress50((): void => {
            context.trackVideoProgress(requestKey, videoLength, 0.5);
        });

        player.onProgress90((): void => {
            context.trackVideoProgress(requestKey, videoLength, 0.9);
        });

        player.onQualityChange((): void => {
            const currentQuality = player.getCurrentQuality();
            context.trackVideoQuality(currentQuality.display);
        });

        return player;
    }

    private loadVideos(): void {
        const cliplistervideos = document.querySelectorAll('.cliplistervideo:not([id])');
        if (cliplistervideos && cliplistervideos.length > 0) {
            let done = false;
            const callWhenExternalCliplisterIsAdded = (event: any): void => {
                if (!done && (!event || !event.currentTarget || !event.currentTarget.readyState ||
                    (/loaded|complete/).test(event.currentTarget.readyState))) {
                    done = true;
                    cliplistervideos.forEach(elm => this.createPlayer(elm, this));
                }
            };
            this.prepareExternalCliplisterStuff(callWhenExternalCliplisterIsAdded);
        }
    }

    private initVideos(): void {
        eventLoader.onLoad(40, (): void => {
            this.loadVideos();
        });
    }

    private trackVideoView(requestkey: string): void {
        this.sendEventToTrackingServer({
            ot_Video: 'view',
            ot_VideoRequestKey: requestkey
        });
    }

    private trackVideoStart(requestKey: string, videoLength: any): void {
        this.sendEventToTrackingServer({
            ot_Video: 'start',
            ot_VideoRequestKey: requestKey,
            ot_VideoLength: videoLength
        });
    }

    private trackVideoProgress(requestkey: string, videoLength: any, progress: number): void {
        this.sendEventToTrackingServer({
            ot_Video: 'progress',
            ot_VideoRequestKey: requestkey,
            ot_VideoLength: videoLength,
            ot_VideoProgress: progress
        });
    }

    private trackVideoQuality(currentQuality: string): void {
        this.sendEventToTrackingServer({
            ot_VideoQuality: currentQuality
        });
    }

    private sendEventToTrackingServer(trackingData: object): void {
        this.trackingService.sendVideoTrackingEvent(trackingData);
    }
}

export type CliplisterViewer = {
    onReady(param: () => void): void;
    onPlay(param: () => void): void;
    onProgress10(param: () => void): void;
    onProgress50(param: () => void): void;
    onProgress90(param: () => void): void;
    onQualityChange(param: () => void): void;
    getAssets(): [
        {
            duration: number;
        }
    ];
    getCurrentAssetIndex(): number;
    getCurrentQuality(): { display: string };
};