export class BrandhubBackground {
    private readonly videoLandscape: HTMLVideoElement | null
    private readonly videoPortrait: HTMLVideoElement | null
    private readonly pauseButtonLandscape: Element | null
    private readonly pauseButtonPortrait: Element | null
    private readonly mobileSizeMediaQuery: MediaQueryList;

    constructor() {
        this.videoLandscape = document.getElementsByClassName("promo_staticcontent--brandhub-background-video--landscape")?.item(0) as HTMLVideoElement;
        this.videoPortrait = document.getElementsByClassName("promo_staticcontent--brandhub-background-video--portrait")?.item(0) as HTMLVideoElement;
        this.pauseButtonLandscape = document.getElementsByClassName("promo_staticcontent--brandhub-background-pause-button--landscape")?.item(0);
        this.pauseButtonPortrait = document.getElementsByClassName("promo_staticcontent--brandhub-background-pause-button--portrait")?.item(0);
        this.mobileSizeMediaQuery = window.matchMedia("(width < 48em)");
        const loadingSpinnerLandscape = document.getElementsByClassName("promo_staticcontent--brandhub-background-loading-spinner--landscape")?.item(0) as HTMLElement;
        const loadingSpinnerPortrait = document.getElementsByClassName("promo_staticcontent--brandhub-background-loading-spinner--portrait")?.item(0) as HTMLElement;
        this.addPauseButtonListener();
        this.addVideoLoadedListener(this.videoLandscape, loadingSpinnerLandscape);
        this.addVideoLoadedListener(this.videoPortrait, loadingSpinnerPortrait);
        this.addAutoPauseScrollListener();
    }

    private addPauseButtonListener(): void {
        [this.videoLandscape, this.videoPortrait, this.pauseButtonLandscape, this.pauseButtonPortrait].forEach(
            element => {
                if (element) {
                    element.addEventListener("click", () => {
                        const videoElement = this.getVideoForScreenSize();
                        const pauseButton = this.getPauseButtonForScreenSize();
                        if (videoElement?.paused) {
                            this.playVideo(videoElement, pauseButton);
                        } else {
                            this.pauseVideo(videoElement, pauseButton);
                        }
                    })
                }
            });
    }

    private playVideo(videoElement: HTMLVideoElement | null, pauseButton: Element | null) {
        videoElement?.play();
        pauseButton?.setAttribute("icon-type", "pause");
    }

    private pauseVideo(videoElement: HTMLVideoElement | null, pauseButton: Element | null) {
        videoElement?.pause();
        pauseButton?.setAttribute("icon-type", "play");
    }

    private addVideoLoadedListener(videoElement: HTMLVideoElement | null, loadingSpinner: HTMLElement | null) {
        if (videoElement) {
            /*                                                                                                                                                       */
            if (videoElement.readyState >= 3) {
                this.setLoadingSpinnerInvisible(loadingSpinner);
            } else {
                videoElement.addEventListener("loadeddata", () => {
                    this.setLoadingSpinnerInvisible(loadingSpinner);
                });
            }
        } else {
            this.setLoadingSpinnerInvisible(loadingSpinner);
        }
    }

    private addAutoPauseScrollListener() {
        window.onscroll = () => {
            if (this.videoLandscape && !this.videoLandscape.paused) {
                this.pauseVideo(this.videoLandscape, this.pauseButtonLandscape);
            }
            if (this.videoPortrait && !this.videoPortrait.paused) {
                this.pauseVideo(this.videoPortrait, this.pauseButtonPortrait);
            }
        }
    }

    private setLoadingSpinnerInvisible(loadingSpinner: HTMLElement | null) {
        if (loadingSpinner) {
            loadingSpinner.style.display = "none";
        }
    }

    private getVideoForScreenSize(): HTMLVideoElement | null {
        return this.mobileSizeMediaQuery.matches ? this.videoPortrait : this.videoLandscape;
    }

    private getPauseButtonForScreenSize(): Element | null {
        return this.mobileSizeMediaQuery.matches ? this.pauseButtonPortrait : this.pauseButtonLandscape;
    }

}