import TrackingService from "./tracking_service";

export default class PromoStaticContent {

    constructor() {
        this.trackingService = new TrackingService();
    }

    trackClick(clickableItem) {
        const container = this._findParentContainer(clickableItem);
        const promoType = this._getPromoType(container);
        const promoFeatureLabels = this._getFeatureLabels(container, promoType);
        const featureOrder = this._getFeatureOrder(container);
        const featureIndex = this._getFeatureIndex(container);
        const masTrackingLabels = this._getMasFeatureTrackingLabels(container);

        this.trackingService.sendClickTrackingEvent(featureOrder, featureIndex, promoType, promoFeatureLabels, masTrackingLabels);
    }

    trackLoad(container) {
        const promoType = this._getPromoType(container);
        const featureOrder = this._getFeatureOrder(container);
        const featureIndex = this._getFeatureIndex(container);
        const promoFeatureLabels = this._getFeatureLabels(container, promoType);
        const masTrackingLabels = this._getMasFeatureTrackingLabels(container);

        this.trackingService.sendLoadedTrackingEvent(featureOrder, featureIndex, promoType, promoFeatureLabels, masTrackingLabels);
    }

    registerScrollTrackObserver(container) {
        /*                       */
        let observer = new IntersectionObserver((entries) => {
            let intersectingEntries = entries.filter(entry => entry.isIntersecting === true);
            if (intersectingEntries.length > 0) {
                this.trackingService.sendScrollTrackingEvent(this._getPromoType(container), this._getFeatureIndex(container));
                intersectingEntries.forEach(entry => {
                    observer.unobserve(entry.target);
                });
            }
        });
        observer.observe(container);
    }

    _getFeatureLabels(container, promoType) {
        switch (promoType) {
            case 'Textlink': {
                const link = container.getElementsByTagName('a')[0];
                const absoluteUrl = link.getAttribute('href');
                const content = absoluteUrl.replace(/^(http|https):\/\/[a-z0-9.-]+\.?otto\.de/, '');
                const labels = {
                    promo_Content: [content]
                }
                if (content.includes("selektion=")) {
                    labels.promo_TargetPsr = [content.split("selektion=")[1].split("&")[0]];
                }
                return labels;
            }
            case 'Video': {
                const videoId = container.getAttribute('data-video-id');
                return {
                    promo_Content: [videoId],
                    ot_VideoRequestKey: [videoId],
                    ot_Video: ["view"]
                };
            }
            case 'PictureInfoCard': {
                return {
                    promo_Content: [container.getAttribute('data-overline')],
                    promo_ImageId: [container.getAttribute('data-image-id')]
                };
            }
            default: {
                return {};
            }
        }
    }

    _getPromoType(container) {
        return container.getAttribute("data-promo-type");
    }

    _getFeatureOrder(container) {
        return parseInt(container.parentElement.getAttribute('data-feature-order'), 0);
    }

    _getFeatureIndex(container) {
        return parseInt(container.getAttribute('data-feature-index'), 0);
    }

    _getMasFeatureTrackingLabels(container) {
        const dataFeatureTrackingLabels = container.closest('[data-feature-tracking-labels]')?.getAttribute("data-feature-tracking-labels");
        return dataFeatureTrackingLabels ? JSON.parse(dataFeatureTrackingLabels) : {};
    }

    _findParentContainer(elem) {
        const className = "promo_staticcontent--trackable-widget";
        while (!elem.classList.contains(className) && (elem = elem.parentElement)) ;
        return elem;
    }

}
